import {
  getCategoryGroups,
  getFavorites,
  getModels,
  getProjectData,
  getProjectUsageData,
} from '@/api'
import { Links, Logo } from '@/components/Common'
import {
  GnbAuth,
  GnbProject,
  GnbProjectModels,
  GnbProjects,
} from '@/components/Layout'
import {
  GNB_TOGGLE_KEY,
  LAYOUT_GNB_COLLAPSE_WIDTH,
  LAYOUT_GNB_WIDTH,
  STRETCH_TOGGLE_KEY,
} from '@/configs'
import { RootState, useAppDispatch } from '@/states'
import {
  setCurrentModel,
  setCurrentProject,
  toggleGnb,
  toggleGnb2,
  toggleStretch,
} from '@/states/actions'
import {
  ApartmentOutlined,
  CloudSyncOutlined,
  CodeSandboxOutlined,
  FileImageOutlined,
  LayoutOutlined,
} from '@ant-design/icons'
import { Button, Layout, message } from 'antd'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
const { Sider } = Layout

export const Gnb = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // Params
  const { projectUid, modelId } = useParams()

  // State (Redux)
  const { layoutState, projectsState, authState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { gnb, gnb2, gnb3 } = layoutState
  const { projectsListInit, currentProject, modelList } = projectsState
  const { init, me } = authState

  // State
  const [isGnb2Collapse, setIsGnb2Collapse] = React.useState<boolean>(false)
  const [isGnb3Collapse, setIsGnb3Collapse] = React.useState<boolean>(false)

  // Effect
  useEffect(() => {
    // GNB 닫기 설정
    setGnbCollapse()

    // 늘리기/줄이기 설정
    setStretchCollapse()
  }, [])

  // Project 정보 가져오기
  useEffect(() => {
    if (
      projectUid &&
      projectsListInit &&
      me &&
      (!currentProject || currentProject.uid !== projectUid)
    ) {
      getProjectData(projectUid)
        .then((res) => {
          const projectData = res.data

          getProjectUsageData(projectUid)
            .then((res) => {
              projectData.usage = res.data

              dispatch(setCurrentProject(projectData))

              dispatch(
                getModels(
                  projectUid,
                  modelId ? (Number(modelId) as number) : null,
                  true
                )
              )

              dispatch(getCategoryGroups(projectUid))
            })
            .catch((e) => {
              message.error(t('error.pageNotFound'))
              navigate(
                process.env.REACT_APP_PROJECT_ID
                  ? `/projects/notFound`
                  : `/projects/`
              )
            })
        })
        .catch((e) => {
          message.error(t('error.pageNotFound'))
          navigate(
            process.env.REACT_APP_PROJECT_ID
              ? `/projects/notFound`
              : `/projects/`
          )
        })
    }
  }, [projectUid, projectsListInit, me])

  // Favorites Model 정보 가져오기
  useEffect(() => {
    if (
      init &&
      projectsListInit &&
      me &&
      process.env.REACT_APP_HIDE_FAVORITES !== '1'
    ) {
      dispatch(getFavorites())
    }
  }, [init, projectsListInit, me])

  // Model 정보 가져오기
  useEffect(() => {
    if (currentProject && projectsListInit && modelId) {
      const targetModel = modelList.find(
        (model) => model.id === Number(modelId)
      )

      dispatch(setCurrentModel(targetModel))
    }
  }, [currentProject, projectsListInit, modelId])

  // GNB Control
  useEffect(() => {
    dispatch(toggleGnb2(!!projectUid))
    setIsGnb2Collapse(false)
  }, [projectUid])

  useEffect(() => {
    // dispatch(toggleGnb3(!!modelId))
    setIsGnb2Collapse(false)
    setIsGnb3Collapse(false)
  }, [modelId])

  /**
   * GNB 열림 설정
   */
  const setGnbCollapse = () => {
    const gnbCookie = Cookies.get(GNB_TOGGLE_KEY)

    if (
      (gnbCookie && gnbCookie === '0') ||
      (window.innerWidth < 768 && gnbCookie !== '1')
    ) {
      dispatch(toggleGnb(false))
    } else {
      dispatch(toggleGnb(true))
    }
  }

  /**
   * GNB 열림 설정
   */
  const setStretchCollapse = () => {
    const stretchCookie = Cookies.get(STRETCH_TOGGLE_KEY)

    if (
      (stretchCookie && stretchCookie === '0') ||
      (window.innerWidth < 768 && stretchCookie !== '1')
    ) {
      dispatch(toggleStretch(false))
    } else {
      dispatch(toggleStretch(true))
    }
  }

  /**
   * GNB Menu Toggle
   */
  const toggleGnbCollapse = () => {
    dispatch(toggleGnb(!gnb))

    Cookies.set(GNB_TOGGLE_KEY, !gnb ? '1' : '0', {
      expires: 86400,
    })
  }

  /**
   * GNB Menu
   * @returns
   */
  const GnbMenus = () => {
    return (
      <div className="px-0">
        <Button
          type="text"
          block
          className={`!w-full !rounded-lg !px-2 !text-left ${
            location.pathname.includes(`/projects/${projectUid}/media`)
              ? '!bg-gray-300 !font-bold'
              : ''
          }`}
          icon={<FileImageOutlined />}
          onClick={() => {
            navigate(`/projects/${projectUid}/media`)
          }}>
          {t('mediaContents')}
        </Button>
        {process.env.REACT_APP_HIDE_CATEGORY !== '1' && (
          <Button
            type="text"
            block
            className={`!w-full !rounded-lg !px-2 !text-left ${
              location.pathname.includes(`/projects/${projectUid}/categories`)
                ? '!bg-gray-300 !font-bold'
                : ''
            }`}
            icon={<ApartmentOutlined />}
            onClick={() => {
              navigate(`/projects/${projectUid}/categories`)
            }}>
            {t('category')}
          </Button>
        )}
        {process.env.REACT_APP_HIDE_PUBLISH !== '1' && (
          <Button
            type="text"
            block
            className={`!w-full !rounded-lg !px-2 !text-left ${
              location.pathname.includes(`/projects/${projectUid}/publish`)
                ? '!bg-gray-300 !font-bold'
                : ''
            }`}
            icon={<CloudSyncOutlined />}
            onClick={() => {
              navigate(`/projects/${projectUid}/publish`)
            }}>
            {t('publishContents')}
          </Button>
        )}
        {process.env.REACT_APP_HIDE_DEVELOPERS !== '1' && (
          <Button
            type="text"
            block
            className={`!w-full !rounded-lg !px-2 !text-left ${
              location.pathname.includes(`/projects/${projectUid}/developers`)
                ? '!bg-gray-300 !font-bold'
                : ''
            }`}
            icon={<CodeSandboxOutlined />}
            onClick={() => {
              navigate(`/projects/${projectUid}/developers/access`)
            }}>
            {t('developers')}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div
      id="gnb"
      style={{
        width: `${gnb ? LAYOUT_GNB_WIDTH + 'px' : LAYOUT_GNB_COLLAPSE_WIDTH}`,
      }}
      className={`transition-all z-[100] !bg-transparent overflow-x-visible ${
        gnb ? 'fixed lg:relative open-gnb h-screen' : 'fixed left-0 top-0 h-12 '
      }`}>
      {gnb && (
        <div
          id="gnb-bg"
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 lg:hidden"
          onClick={() => dispatch(toggleGnb(false))}
        />
      )}
      {gnb2 && isGnb2Collapse && (
        <div
          id="gnb2-bg"
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20"
          onClick={() => setIsGnb2Collapse(false)}
        />
      )}

      <div className={`h-full flex flex-col pb-3`}>
        <div className="flex items-center">
          {/* 상단 아이콘: 시작 */}
          <div className="p-2">
            <div
              className="flex-none w-8 h-8 flex items-center justify-center hover:bg-gray-300 transition-all rounded-lg cursor-pointer"
              onClick={() => {
                toggleGnbCollapse()
              }}>
              {gnb ? (
                <LayoutOutlined className="text-xl" />
              ) : (
                <Logo isIcon customClass="w-6 h-6 rounded" />
              )}
            </div>
          </div>
          {/* 상단 아이콘: 끝 */}
          {gnb ? (
            <>
              <Link
                to={
                  process.env.REACT_APP_PROJECT_ID
                    ? `/projects/${process.env.REACT_APP_PROJECT_ID}`
                    : `/projects`
                }
                className="hidden lg:block z-[100]">
                <Logo customClass="w-20 h-8" />
              </Link>
              <Link
                to={
                  process.env.REACT_APP_PROJECT_ID
                    ? `/projects/${process.env.REACT_APP_PROJECT_ID}`
                    : `/projects`
                }
                className="lg:hidden z-[100]">
                <Logo isIcon customClass="w-6 h-8 rounded" />
              </Link>
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`w-full h-full overflow-x-visible px-3 grow transition-all ${
            gnb ? '' : '-translate-x-full'
          }`}
          style={{
            height: `calc(100% - 3.5rem)`,
          }}>
          <div className="relative h-full">
            {/* GNB depth 1 (Accuont, Projects): 시작 */}
            <div
              className={`transition-all overflow-y-auto h-full ${
                gnb2 && !isGnb2Collapse ? 'py-1' : ''
              }`}>
              <div
                className={`w-full h-full overflow-y-auto bg-gray-100 border border-gray-300 shadow-lg shadow-gray-300 rounded-lg`}>
                <div className="px-3 space-y-3 pb-3">
                  <div className="sticky top-0 bg-gray-100 z-10 space-y-3 pt-3">
                    <GnbAuth />
                    <hr className="border-gray-300" />
                  </div>
                  {process.env.REACT_APP_PROJECT_ID ? (
                    <>
                      <div className="pb-0">
                        <GnbProjectModels isGnb2Collapse={isGnb2Collapse} />
                      </div>
                      <hr className="border-gray-300" />
                      <GnbMenus />
                    </>
                  ) : (
                    <>
                      <div>
                        <GnbProjects />
                      </div>
                      <div className="space-y-3 pl-2">
                        <hr className="border-gray-300" />
                        <Links />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* GNB depth 1 (Accuont, Projects): 끝 */}
            {/* GNB depth 2 (Project): 시작 */}
            {!process.env.REACT_APP_PROJECT_ID && gnb2 ? (
              <div
                className={`z-[1000] absolute top-0 ${
                  gnb && isGnb2Collapse ? '-left-1 translate-x-full' : 'left-1'
                } transition-all w-full h-full`}>
                <div className="relative h-full">
                  <div
                    className={`absolute top-0 left-0 z-10 w-full h-full overflow-y-auto bg-gray-100 border border-gray-300 shadow-lg shadow-gray-300 rounded-lg`}>
                    <div className="px-3 space-y-3 pb-3">
                      <div
                        className="sticky top-0 bg-gray-100 z-10 space-y-3 pt-3"
                        onClick={() => setIsGnb2Collapse(!isGnb2Collapse)}>
                        <GnbProject isGnb2Collapse={isGnb2Collapse} />
                        <hr className="border-gray-300" />
                      </div>
                      <div className="pb-0">
                        <GnbProjectModels isGnb2Collapse={isGnb2Collapse} />
                      </div>
                      <hr className="border-gray-300" />
                      <GnbMenus />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* GNB depth 2 (Project): 끝 */}
            {/* GNB depth 3 (Model): 시작 */}
            {/* {gnb2 && gnb3 ? (
              <div
                className={`z-[1010] absolute top-0 transition-all w-full h-full p-0  ${
                  gnb && isGnb3Collapse && isGnb2Collapse
                    ? '-left-2 translate-x-[200%]'
                    : ''
                }  ${
                  gnb && isGnb3Collapse && !isGnb2Collapse
                    ? '-left-2 translate-x-full'
                    : ''
                } ${!isGnb2Collapse && !isGnb3Collapse ? 'left-2' : ''}`}>
                <div className="relative h-full">
                  <div
                    className={`absolute top-0 left-0 z-10 w-full h-full overflow-y-auto bg-gray-100 border border-gray-300 shadow-lg shadow-gray-300 rounded-lg`}>
                    <div
                      onClick={() => {
                        setIsGnb3Collapse(!isGnb3Collapse)
                        if (isGnb3Collapse) {
                          setIsGnb2Collapse(false)
                        }
                      }}>
                      Model Toggle
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )} */}
            {/* GNB depth 3 (Model): 끝 */}
          </div>
        </div>
      </div>
    </div>
  )
}
